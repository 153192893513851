var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        {
          staticClass:
            "header header__gradient header__gradient--fromBottom header__gradient--fromLeft",
          style: _vm.backgroundImgLink,
        },
        [
          _c("div", { staticClass: "container beat" }, [
            _c(
              "div",
              { staticClass: "beat__artworkwrapper" },
              [
                _c(
                  "a",
                  { staticClass: "play", on: { click: _vm.togglePlayStatus } },
                  [
                    _c("icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isAudioPaused || _vm.beat !== _vm.beatPlaying,
                          expression: "isAudioPaused || beat !== beatPlaying",
                        },
                      ],
                      attrs: { name: "play" },
                    }),
                    _c("icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                          expression: "!isAudioPaused && beat === beatPlaying",
                        },
                      ],
                      attrs: { name: "pause" },
                    }),
                  ],
                  1
                ),
                _c("img", {
                  staticClass: "beat__image",
                  attrs: { src: _vm.beatImageOrProducerLogo(_vm.beat) },
                }),
                _vm.beat.sampled
                  ? _c("icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "This beat contains uncleard samples",
                          expression: "'This beat contains uncleard samples'",
                        },
                      ],
                      staticClass: "beat__copyrightIcon",
                      attrs: { name: "copyright" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "beat__details" }, [
              _c("div", { staticClass: "beat__details--name" }, [
                _c("h2", [_vm._v(_vm._s(_vm.beat.name))]),
              ]),
              _c("div", { staticClass: "beat__details--producer" }, [
                _vm.beat.producer.image_logo_url
                  ? _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.beat.producer.image_logo_url,
                          expression: "beat.producer.image_logo_url",
                        },
                      ],
                    })
                  : _vm._e(),
                _c(
                  "h3",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Producer",
                            params: { slug: _vm.beat.producer.slug },
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.beat.producer.display_name))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "beat__actions" },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Demo Download",
                      expression: "'Demo Download'",
                    },
                  ],
                  staticClass: "beat__actions--download",
                  class: { isDisabled: !_vm.beat.demo_download_allowed },
                  attrs: { name: "download" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.demoDownloadBeat(_vm.beat)
                    },
                  },
                }),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--normal btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.SET_ACTIVE_MODAL({
                          modal: _vm.ModalType.LicenseOptionSelection,
                          payload: _vm.beat,
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.beatAlreadyInCart
                          ? "Already In Cart"
                          : "+ ADD TO CART"
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }