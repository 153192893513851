














import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import Beat from '~/views/Beat.vue'
import { BeatFilterType, fetchBeats, fetchSingleBeat } from '~/api/beats'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import BeatTable from '@/components/BeatTable.vue'
import { ParentStateType } from '~/const/parent-state-type'

@Component({
    components: { Beat, BeatTable, VLoadSpinner }
})
export default class PBeat extends Vue {
    @Prop({ type: Number }) id!: IBeat['id']
    ParentStateType = ParentStateType

    beat: IBeat = null
    relatedBeats: IBeat[] = []
    isLoading = false

    async fetchRelatedBeats() {
        const artistTypeSlug = this.beat.artist_type.slug
        const relatedBeats = await fetchBeats({ filterType: BeatFilterType.Related, slug: artistTypeSlug })
        this.relatedBeats = relatedBeats.filter(item => item.id !== this.beat.id)
    }

    async fetchBeat() {
        this.isLoading = true
        this.beat = await fetchSingleBeat(this.id)
        this.fetchRelatedBeats()
        this.isLoading = false
    }

    mounted() {
        this.fetchBeat()
    }

}
